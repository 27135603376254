<template>
  <div>
    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

    <h4 qid="maintenance-create-s-title">
      {{$t('projects.create.title')}}
    </h4>

    <div class="card">
      <div class="card-body">
        <div>
          <b-alert v-if="error.length"
                   show
                   variant="danger"
                   qid="create-s-error"
          >
            {{ error }}
          </b-alert>
          <b-form @submit="formSubmit" qid="create-s-form">
            <div class="form-container">
              <div class="row">
                <div class="col-sm-6 pl-5 pr-3">
                  <b-form-group>
                    <label qid="create-s-name-label">
                      {{ $t('projects.create.title_label') }}
                    </label>
                    <b-form-input
                      qid="create-s-form-name"
                      type="text"
                      v-model="sForm.title"
                      :maxLength="255"
                      required
                      :placeholder="$t('projects.create.title_placeholder')"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <label qid="create-s-description-label">
                      {{ $t('projects.create.description_label') }}
                    </label>
                    <b-form-textarea
                      qid="create-s-form-description"
                      v-model="sForm.description"
                      rows="6"
                      max-rows="6"
                      required
                      :placeholder="$t('projects.create.description_placeholder')"
                    ></b-form-textarea>
                  </b-form-group>
                  <b-form-group>
                    <label qid="create-s-users-label">
                      {{ $t('projects.create.owners_label') }}
                    </label>
                    <multiselect
                        v-model="selectedUsers"
                        :options="users"
                        :multiple="true"
                        :taggable="false"
                        track-by="slug"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        :placeholder="$t('projects.create.owners_placeholder')"
                        label="name"
                        @search-change="loadUsers"
                        qid="create-s-users-select"
                    >
                      <template slot="tag" slot-scope="props">
                        <span class="mr-3 user-tag">
                          <Avatar :object="props.option" size="xs"></Avatar>
                          <span class="ml-2">{{ props.option.name }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <Avatar :object="props.option" size="xs"></Avatar>
                        <span class="ml-2">
                          {{ props.option.name }}
                        </span>
                      </template>
                    </multiselect>
                  </b-form-group>
                </div>
                <div class="col-sm-6 pl-3 pr-5">
                  <b-form-group>
                    <label qid="create-p-pg-label">
                      {{ $t('projects.create.type_label') }}
                    </label>
                    <multiselect
                        v-model="projectType"
                        :options="projectTypes"
                        :placeholder="$t('projects.create.type_placeholder')"
                        label="name"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        qid="create-p-form-pg"
                        @selected="selectType"
                        @remove="deselectType"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group v-if="(projectType && projectType.value != 'security_measure' && projectType.value != 'risk_assessment') || !projectType">
                    <label qid="create-p-pg-label">
                      {{ $t('projects.create.scope_label') }}
                    </label>
                    <multiselect
                        v-model="projectScope"
                        :options="projectScopes"
                        :placeholder="$t('projects.create.scope_placeholder')"
                        label="name"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        qid="create-p-form-pg"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group v-if="projectType && projectType.value == 'risk_assessment'">
                    <label qid="create-p-pg-label">
                      {{ $t('projects.create.assessment_label') }}
                    </label>
                    <multiselect
                        v-model="projectAssessment"
                        :options="projectAssessments"
                        :placeholder="$t('projects.create.assessment_placeholder')"
                        label="name"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        qid="create-p-form-pg"
                    ></multiselect>
                    <p class="mt-2 text-formatted" v-if="projectAssessment && projectAssessment.id && projectAssessment.description">
                      {{ projectAssessment.description }}
                    </p>
                  </b-form-group>
                  <b-form-group>
                    <label qid="create-do-tags-label">
                      {{ $t('processes.create.tags_label') }}
                    </label>
                    <multiselect
                            v-model="selectedTags"
                            :options="tags"
                            :multiple="true"
                            :select-label="$t('system.dropdown_select_label')"
                            :selected-label="$t('system.dropdown_selected_label')"
                            :deselect-label="$t('system.dropdown_deselect_label')"
                            :taggable="true"
                            track-by="name_translated"
                            :placeholder="$t('processes.create.tags_placeholder')"
                            label="name_translated"
                            qid="create-do-tags-select"
                            @search-change="loadTags"
                            @tag="addTag"
                    ></multiselect>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="text-right">
              <b-button class="mt-3"
                        variant="secondary"
                        @click="goToList"
                        qid="create-s-cancel-button"
              >
                {{ $t('projects.create.cancel') }}
              </b-button>
              <b-button type="submit"
                        class="mt-3 ml-3"
                        :disabled="buttonDisabled"
                        variant="success"
                        qid="create-s-submit-button"
              >
                <span v-if="buttonDisabled" class="mr-1">
                  <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                </span>
                {{ $t('projects.create.submit') }}
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';

  export default {
    name: 'CreateProject',
    data: function() {
      return {
        breadcrumbItems: [
          {
            text: this.$t('dashboard.title'),
            to: '/'
          },
          {
            text: this.$t('projects.title'),
            active: true
          },
          {
            text: this.$t('projects.create.title'),
            active: true
          }
        ],
        sForm: {
          title: '',
          description: '',
          client:''
        },
        countries: [],
        selectedCountries: [],
        organizations: [],
        selectedOrganizations: [],
        tags: [],
        selectedTags: [],
        error: '',
        users: [],
        selectedUsers: [],
        newTags: [],
        buttonDisabled: false,
        projectAssetTypes: [
          { name: this.$t('projects.type.update'), value: 'update' }
        ],
        projectControlTypes: [
          { name: this.$t('projects.type.control'), value: 'control' },
          { name: this.$t('projects.type.security_measure'), value: 'security_measure' }
        ],
        projectAssessmentTypes: [
          { name: this.$t('projects.type.risk_assessment'), value: 'risk_assessment' },
        ],
        projectScopes: [

        ],
        projectType: [],
        projectTypes: [],
        projectScope: [],
        projectAssessment: [],
        projectAssessments: [],
        create_type: '',
        control_type: '',

      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadTags()
        this.loadUsers()
        this.setTypes()
        if (this.getClient.assessmentModule) {
          this.loadAssessments()
        }
      },
      projectType: function () {
        this.projectScope = []
      },

    },
    methods: {
      addTag(newTag) {
        const tag = {
          name: newTag,
          translatable: newTag,
          name_translated: newTag
        }
        this.selectedTags.push(tag)
        this.newTags.push(tag)
      },
      goToList() {
        if (this.$route.params.create_type) {
          if (this.$route.params.create_type === 'assessment') {
            this.$router.push('/projects/assessment-projects')
          } else if (this.$route.params.create_type === 'control') {
            this.$router.push('/projects/control-projects')
          } else if (this.$route.params.create_type === 'update') {
            this.$router.push('/projects/asset-projects')
          }
        } else {
          this.$router.push('/projects/gantt')
        }
      },
      formSubmit(evt) {
        this.buttonDisabled = true;
        let formData = new FormData();
        let files = document.querySelector('#file');

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        // Append list of users to FormData
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            formData.append("users[]", this.selectedUsers[i].id);
          }
        }

        // Additional check - if scope is selected, append it to form data
        if (this.projectScope && this.projectScope.value) {
          formData.append("scope", this.projectScope.value);
        }

        // Additional check - if type is selected, append it to form data
        if (this.projectType && this.projectType.value) {
          formData.append("type", this.projectType.value);
        }

        if (this.projectAssessment && this.projectAssessment.id) {
          formData.append("assessment", this.projectAssessment.id);
        }

        if (this.control_type && this.control_type != '') {
          formData.append("control_type", this.control_type);
        }

        if (this.selectedTags && this.selectedTags.length) {
          for (var i = 0; i < this.selectedTags.length; i++) {
            formData.append("tags[]", this.selectedTags[i].name_translated);
          }
        }

        formData.append("title", this.sForm.title);
        formData.append("description", this.sForm.description);
        formData.append("client", this.getClient.slug);

        if (
            ((this.projectScope && this.projectScope.value && this.projectType != null && this.projectType.value) ||
            (this.projectType != null && this.projectType.value && this.projectType.value == 'security_measure') ||
            (this.projectType != null && this.projectType.value && this.projectType.value == 'risk_assessment' && this.projectAssessment && this.projectAssessment.id)) &&
            this.selectedUsers && this.selectedUsers.length
          ) {
          piincHttp.post('/projects', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function(response) {
            self.$toastr('success', self.$t('projects.create.success'))
            self.buttonDisabled = false;
            self.$router.push({ name: 'projects.view', params: { slug: response.data.slug }})
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
              // Print whole array of actual errors beside message
              self.error = self.error + '\n\n' + JSON.stringify(error.data.errors);
            }
          })
        } else {
          this.error = '';
          if (!this.projectType || (this.projectType && !this.projectType.value)) {
            this.error += this.$t('system.type_error_message')
          }
          if (!this.selectedUsers.length) {
            this.error += this.$t('system.users_error_message')
          }
          if ((!this.projectScope || (this.projectScope && !this.projectScope.value)) && ((this.projectType && this.projectType.value && this.projectType.value != 'risk_assessment' && this.projectType.value != 'security_measure') || (!this.projectType || (this.projectType && !this.projectType.value)))) {
            this.error += this.$t('system.scope_error_message')
          }
          if ((!this.projectAssessment || (this.projectAssessment && !this.projectAssessment.id)) && this.projectType && this.projectType.value && this.projectType.value == 'risk_assessment') {
            this.error += this.$t('system.assessment_error_message')
          }
          this.buttonDisabled = false;
        }
      },
      loadAssessments(query) {
        let self = this;

        piincHttp.get('assessments', { params:
          {
            per_page: 100,
            type: 'risk',
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.projectAssessments = response.data.items
          if (self.projectAssessments && !self.projectAssessments.length && !query) {
            self.projectAssessments.push({title: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadTags(query) {
        let self = this;
        piincHttp.get('tags', { params:
                  {
                    client: this.getClient.slug,
                    keyword: query ? query : undefined
                  }
        }).then(function(response) {
          self.tags = response.data
          for (var i = 0; i < self.newTags.length; i++) {
            self.tags.push(self.newTags[i]);
          }
          if (self.tags && !self.tags.length && !query) {
            self.tags.push({name_translated: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      selectType(type) {
        if (type.value == 'validation') {
          if (!this.getClient.gdprAdvancedModule) {
            let processIndex = _.findIndex(this.projectScopes, function(o) { return o.value == 'process'; });
            this.projectScopes.splice(processIndex, 1);
            let activityIndex = _.findIndex(this.projectScopes, function(o) { return o.value == 'activity'; });
            this.projectScopes.splice(processIndex, 1);
          }
        } else {
          if (this.getClient.processingActivitiesModule) {
            if (_.findIndex(this.projectScopes, function(o) { return o.value == 'process'; }) == -1) {
              this.projectScopes.push({ name: this.$t('projects.scope.process'), value: 'process' })
            }
            if (_.findIndex(this.projectScopes, function(o) { return o.value == 'activity'; }) == -1) {
              this.projectScopes.push({ name: this.$t('projects.scope.activity'), value: 'activity' })
            }
          }
        }
      },
      deselectType(type) {
        if (this.getClient.processingActivitiesModule) {
          if (_.findIndex(this.projectScopes, function(o) { return o.value == 'process'; }) == -1) {
            this.projectScopes.push({ name: this.$t('projects.scope.process'), value: 'process' })
          }
          if (_.findIndex(this.projectScopes, function(o) { return o.value == 'activity'; }) == -1) {
            this.projectScopes.push({ name: this.$t('projects.scope.activity'), value: 'activity' })
          }
        }
      },
      setTypes() {
        this.control_type = this.$route.params.control_type

        if (this.$route.params.create_type) {
          this.create_type = this.$route.params.create_type
          if (this.$route.params.create_type === 'assessment') {
            this.projectTypes = this.projectAssessmentTypes
          } else if (this.$route.params.create_type === 'control') {
            this.projectTypes = this.projectControlTypes
          } else if (this.$route.params.create_type === 'update') {
            this.projectTypes = this.projectAssetTypes
            if (this.getClient.gdprAdvancedModule) {
              this.projectTypes.push({ name: this.$t('projects.type.validation'), value: 'validation' })
            }
          }
        } else {
          this.projectTypes.push(...this.projectAssetTypes)
          if (this.getClient.riskModule) {
            this.projectTypes.push({ name: this.$t('projects.type.control'), value: 'control' })
            this.projectTypes.push({ name: this.$t('projects.type.security_measure'), value: 'security_measure' })
          }
          if (this.getClient.assessmentModule) {
            this.projectTypes.push({ name: this.$t('projects.type.risk_assessment'), value: 'risk_assessment' })
          }
          if (this.getClient.gdprAdvancedModule) {
            this.projectTypes.push({ name: this.$t('projects.type.validation'), value: 'validation' })
          }

        }

        if (this.$route.params.create_scope) {
          if (this.$route.params.create_scope == 'processingActivities') {
            this.projectScopes.push({ name: this.$t('projects.scope.process'), value: 'process' })
            this.projectScopes.push({ name: this.$t('projects.scope.activity'), value: 'activity' })
          }
        } else {
          if (this.getClient.processingActivitiesModule) {
            this.projectScopes.push({ name: this.$t('projects.scope.process'), value: 'process' })
            this.projectScopes.push({ name: this.$t('projects.scope.activity'), value: 'activity' })
          }
          if (this.getClient.systemModule) {
            this.projectScopes.push({ name: this.$t('projects.scope.system'), value: 'system' })
          }
          if (this.getClient.contractBasicModule) {
            this.projectScopes.push({ name: this.$t('projects.scope.third_party'), value: 'third_party' })
          }
          if (this.getClient.policyModule) {
            this.projectScopes.push({ name: this.$t('projects.scope.policy'), value: 'policy' })
          }
        }
      }
    },
    components: {
      Avatar
    },
    created () {
      if (this.getClient && this.getClient.slug) {
        this.loadTags()
        this.loadUsers()
        this.setTypes()
        if (this.getClient.assessmentModule) {
          this.loadAssessments()
        }
      }
    }
  }
</script>
